<template>
    <div class="ECharts-com" :id="id"></div>
</template>

<script>
import * as ECharts from "echarts"
export default {
    name: 'MyECharts',
    components: {},
    props: {
        id: {
            type: String,
            default: ""
        },
        options: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            echarts: null
        }
    },
    computed: {},
    methods: {
        init() {
            const echarts = ECharts.init(document.getElementById(this.id));
            if(!echarts) return;
            echarts.setOption(this.options);
            this.echarts = echarts;
        },
        resize() {
            this.echarts && this.echarts.resize();
        }
    },
    mounted() {
        this.init();
    },
}
</script>

<style scoped lang="scss">
.ECharts-com {
    box-sizing: border-box;
    height: 100% !important;
    width: 100% !important;
}
</style>
